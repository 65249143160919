<style scoped>
  #per_page {
    width: 100px;
    display: inline;
  }
</style>
<template>
  <b-card no-body cols="12">
    <b-col md="12">

      <b-row cols="12">
      <b-col :cols="$route.path == '/companies' ? 6 : 6">
        <h2
          v-if="title"
          class="text-blue font-weight-bold mt-1"
          v-html="$t(title) ? $t(title) : ''"
        ></h2>
        
      </b-col>
      <b-col v-if="actionButton" align="end" class="col-md-6 mt-1">
        <b-button
          @click="$emit('actionButtonTab')"
          :variant="actionButton.color"
        >
          {{ $t(actionButton.text) }}
        </b-button>
      </b-col>
      <b-col cols="6" v-if="$route.path == '/companies'">
        <div class=" compnay-grp-btn mt-3">
          <b-button
            :variant="compFilter == 'all' ? 'primary' : 'outline-primary'"
            class="company-btn"
            @click="filterComanies(3)"
            >{{ $t("all") }}</b-button
          >
          <b-button
            :variant="compFilter == 0 ? 'primary' : 'outline-primary'"
            @click="filterComanies(0)"
            class="company-btn"
            >{{ $t("sellers") }}</b-button
          >
          <b-button
            :variant="compFilter == 2 ? 'primary' : 'outline-primary'"
            @click="filterComanies(2)"
            class="company-btn"
            >{{ $t("debtors") }}</b-button
          >
          <b-button
            :variant="compFilter == 1 ? 'primary' : 'outline-primary'"
            @click="filterComanies(1)"
            >{{ $t("buyers") }}</b-button
          >
        </div>
      </b-col>

      <b-col cols="6" class="pt-2" v-if="showSearch">
        <b-form-group
          :label="$t('filter')"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              @input="filterItems"
              id="filterInput"
              v-model="filter"
              type="search"
              :placeholder="$t('type_to_search')"
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="paginateItems('clear')">
                {{ $t("clear") }}
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    </b-col>
    <b-card-body class="pt-0" v-if="showSearch">
      <div class="d-flex justify-content-between flex-wrap">
     
      </div>
    </b-card-body>
    <br v-if="!showSearch" />

    <b-table
      striped
      responsive
      hover
      class="position-relative table-responsive"
      :per-page="perPage"
      :current-page="0"
      :items="data"
      :fields="headers"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @sort-changed="sortChanged"
      show-empty
    >
      <slot v-for="(_, name) in $slots" :name="name" :slot="name">  </slot>
      <template #head()="scope">
        <div class="text-nowrap">{{ $t(scope.label) }}</div>
      </template>

      <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
      <template
        v-for="(_, name) in $scopedSlots"
        :slot="name"
        slot-scope="slotData"
        ><slot :name="name" v-bind="slotData" />
      </template>
      <template #empty="scope">
        <div v-if="loader">
          <CustomLoader :show="true" />
        </div>
        <div v-else-if="data.length < 1 && !loader">
          {{ scope.emptyText }}
        </div>
      </template>
    </b-table>
    <b-card-body class="d-flex justify-content-end flex-wrap pt-0">
      <div class="d-flex justify-content-between align-items-center w-100">
        <div>
          Show
          <b-form-select @change="paginateItems('clear')" v-model="perPage" id="per_page" :options="page_options"></b-form-select> items
        </div>
        <b-pagination
            @input="paginateItems"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
  BCardTitle,
  BRow,
  BCol,
  BLink,
  BCard,
} from "bootstrap-vue";
import CustomLoader from "@/components/Common/CustomLoader";

export default {
  name: "Table",
  components: {
    CustomLoader,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BCard,
    BRow,
    BCol,
    BLink,
    BCardTitle,
  },
  data() {
    return {
      perPage: 20,
      page_options: [
          { value: 20, text: '20' },
          { value: 50, text: '50' },
          { value: 100, text: '100' },
          { value: 200, text: '200' },
      ],
      pageOptions: [3, 5, 12],
      totalRows: 1,
      currentPage: 1,
      sortBy: "id",
      sortDesc: true,
      sortDirection: "asc",
      filter: "",
      tableKey: "initTable",
      filterOn: [],
      loader: false,
      renderPagination: true,
      pageTitle: {},
      headers: [],
      totalItems: 0,
      data: [],
      compFilter: "all",
    };
  },
  watch: {
    updateData(newValue, oldValue) {
      if (newValue != oldValue) {
        this.$emit("updateProp");
        this.paginateItems();
      }
    },
  },
  props: [
    "title",
    "fields",
    "url",
    "showSearch",
    "updateData",
    "page",
    "body",
    "actionButton",
  ],
  computed: {
    sortOptions() {
      return this.headers
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.text, value: f.value }));
    },
    rows() {
      return this.totalItems;
    },
  },
  created() {
    this.pageTitle = this.title;
    this.headers = this.fields ? this.fields : [];
    this.paginateItems();
  },
  methods: {
    filterComanies(type) {
      // this.$emit("filterCompanies", type);
      this.compFilter = type;
      if (type != 3) {
        this.$http
          .post("/company/admin/list", { role: type })
          .then((response) => {
            this.data = response.data.value.data;
            this.totalItems = response.data.value.total;
            this.currentPage = response.data.value.current_page;
          });
      } else {
        this.paginateItems();
      }
    },
    paginateItems(type) {
      this.loader = true;
      if (type == "clear") {
        this.filter = "";
      }

      this.data = [];
      if (this.url) {
        this.$http
          .post(
            `${this.url}?page=${this.currentPage}&sortDesc=${
              this.sortDesc ? "desc" : "asc"
            }&sortBy=${this.sortBy}&search=${this.filter}&per_page=${this.perPage}`,
            this.body ? this.body : {}
          )
          .then((res) => {
            if (res) {
              if(res.data.value.investors && res.data.value.investors.data) {
                this.data = res.data.value.investors.data;
                this.totalItems = res.data.value.investors.total;
                this.currentPage = res.data.value.investors.current_page;
              }else if (res.data.value && res.data.value.data) {
                this.data = res.data.value.data;
                this.totalItems = res.data.value.total;
                this.currentPage = res.data.value.current_page;
              } else if (res.data.value) {
                this.data = res.data.value;
                this.totalItems = res.data.total;
                this.currentPage = res.data.current_page;
              }
              this.$emit("emitNextStep", this.data);
            }
            this.loader = false;
          });
      }
    },
    sortChanged(e) {
      this.sortDesc = e.sortDesc;
      this.sortBy = e.sortBy;
      this.data = [];
      if (this.url) {
        this.$http
          .post(
            `${this.url}?sortDesc=${e.sortDesc ? "desc" : "asc"}&sortBy=${
              e.sortBy ? e.sortBy : e
            }`
          )
          .then((res) => {
            if (res) {
              if (res.data.value && res.data.value.data) {
                this.data = res.data.value.data;
                this.totalItems = res.data.value.total;
                this.currentPage = res.data.value.current_page;
              } else {
                this.data = res.data.value;
                this.totalItems = res.data.value.total;
                this.currentPage = res.data.value.current_page;
              }
            }
          });
      }
    },

    filterItems() {
      this.data = [];
      if (this.url) {
        this.$http
          .post(
            `${this.url}?search=${this.filter}&sortDesc=${
              this.sortDesc ? "desc" : "asc"
            }&sortBy=${this.sortBy}`
          )
          .then((res) => {
            if (res) {
                console.log(res.data.value.data)
              if (res.data.value && res.data.value.data) {
                this.data = res.data.value.data;
                this.totalItems = res.data.value.total;
                this.currentPage = res.data.value.current_page;
              } else {
                this.data = res.data.value;
                this.totalItems = res.data.value.total;
                this.currentPage = res.data.value.current_page;
              }
            }
          });
      }
    },
  },
};
</script>
<style>
.company-btn {
 margin-right: 7px;
}
.compnay-grp-btn{
  display: inline-block;
}
</style>

